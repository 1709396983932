import React from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { FormControl, OutlinedInputProps } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input, { InputProps } from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

type OutlinedProps = Omit<OutlinedInputProps, 'error'>;

interface InputOutlinedStyled extends OutlinedProps {
  error: any;
}
interface InputStyled extends InputProps {
  error: any;
}

const ErrorText = styled(FormHelperText)`
  font-size: 10px !important;
`;
// const InputOutlinedStyled = styled(OutlinedInput)<InputOutlinedStyled>``;
const InputStyled = styled(Input)<InputStyled>``;
type TProps = {
  name: string;
  label: string;
  placeholder: string;
  outlined?: boolean;
};
export default ({ name, label, placeholder, outlined }: TProps) => {
  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext<
    FormikValues
  >();
  const valueTyped = values[name];
  const touchedTyped = touched[name];
  const errorTyped = errors[name];
  return (
    <FormControl>
      {/* <InputLabel>{label}</InputLabel> */}

      {(outlined && (
        <TextField
          label={label}
          value={valueTyped}
          helperText={errors && errorTyped && touchedTyped}
          placeholder={placeholder}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
          }}
          onBlur={() => {
            setFieldTouched(name, true);
          }}
          id={name}
          aria-describedby={`${name}-text`}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
        />
      )) || (
        <InputStyled
          value={valueTyped}
          error={errors && errorTyped && touchedTyped}
          placeholder={placeholder}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
          }}
          onBlur={() => {
            setFieldTouched(name, true);
          }}
          id={name}
          aria-describedby={`${name}-text`}
        />
      )}
      <ErrorText error id={`${name}-text`}>
        {errors && touchedTyped && errorTyped}
      </ErrorText>
    </FormControl>
  );
};
