import React from 'react';
import { Formik } from 'formik';
import { useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';
// import { useDispatch } from 'react-redux';
import { ForgotPasswordSchema } from '../../utils/validationSchemas';
import Input from '../Common/formik/input';
import { BREAKPOINTS } from '../../utils/constants';
import Button from '../Common/formik/button';
import useUser from '../../hooks/auth/useUser';
// import AuthActionCreator from '../../actions/creator/auth';
// import { useResetPasswordRequestLoader } from '../../hooks/auth';

type TStyledProps = {
  isMobile: boolean;
};
const FormContainer = styled('div')<TStyledProps>`
  display: flex;
  width: 100%;
  flex-direction: ${({ isMobile }) => (isMobile && 'column') || 'row'};
  justify-content: space-between;
  margin-bottom: 10px;
  .MuiFormControl-root {
    flex-grow: 1;
    input {
      padding: 10px 0;
    }
  }
`;
const StyledButton = styled(Button)`
  width: 100%;
  height: 50px;
  margin-top: 20px !important;
  background: ${({ theme }) => theme.colors.primary} !important;
  color: white !important;
  &:hover {
    background: ${({ theme }) => theme.colors.primary} !important;
  }
`;
const ResetPasswordContainer = styled(FormContainer)``;

type Props = {
  setModal: (show: boolean) => void;
};

const ResetPasswordForm = ({ setModal }: Props) => {
  const { resetUserPassword } = useUser();
  const isMobile = useMediaQuery(BREAKPOINTS.MOBILE);
  return (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={async ({ email }, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        const success = await resetUserPassword(email);
        if (success) {
          setModal(false);
        }
        formikHelpers.setSubmitting(false);
      }}
      validationSchema={ForgotPasswordSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <ResetPasswordContainer isMobile={isMobile}>
            <Input label="Email" name="email" placeholder="Enter Email address" />
          </ResetPasswordContainer>
          <StyledButton
            buttonText="Submit"
            loading={isSubmitting}
            type="submit"
            loaderColor="#fff"
          />
        </form>
      )}
    </Formik>
  );
};
export default ResetPasswordForm;
