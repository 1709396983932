import React from 'react';
import Link from 'next/link';
import { Formik } from 'formik';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import { useMediaQuery } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MuiLink from '@material-ui/core/Link';

import { RegisterSchema } from 'src/utils/validationSchemas';
import { BREAKPOINTS } from 'src/utils/constants';
import useUser from 'src/hooks/auth/useUser';
import Input from '../Common/formik/input';
import PasswordInput from '../Common/formik/input/Password';
import Button from '../Common/formik/button';

type TStyledProps = {
  isMobile?: boolean;
};
const ErrorText = styled(FormHelperText)`
  font-size: 10px !important;
`;
const FormContainer = styled('div')<TStyledProps>`
  display: flex;
  width: 100%;
  flex-direction: ${({ isMobile }) => (isMobile && 'column') || 'row'};
  justify-content: space-between;
  margin-bottom: 10px;
  .MuiFormControl-root {
    flex-grow: 1;
    input {
      padding: 10px 0;
    }
  }
`;
const StyledButton = styled(Button)`
  width: 100%;
  height: 50px;
  margin-top: 20px !important;
  background: ${({ theme }) => theme.colors.primary} !important;
  color: white !important;
  &:hover {
    background: ${({ theme }) => theme.colors.primary} !important;
  }
`;
const SignUpContainter = styled(FormContainer)<TStyledProps>`
  flex-direction: ${({ isMobile }) => (isMobile && 'column') || 'row'};
  flex-wrap: wrap;
  .MuiFormControl-root {
    flex-basis: 50%;
    max-width: ${({ isMobile }) => (isMobile && '100%') || '48%'};
    margin-top: ${({ isMobile }) => (isMobile && '10px !important') || '5px'};
    :nth-child(1),
    :nth-child(2) {
      margin-top: 0;
    }
  }
`;
const StyledFormControl = styled(FormControl)<TStyledProps>`
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
`;

const RegisterForm = () => {
  const { registerUserWithEmail } = useUser();
  const isMobile = useMediaQuery(BREAKPOINTS.MOBILE);

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        termsAndConditions: false,
      }}
      onSubmit={(values, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        registerUserWithEmail(values);
        formikHelpers.setSubmitting(false);
      }}
      validationSchema={RegisterSchema}
    >
      {({
        values,
        setFieldValue,
        handleSubmit,
        errors,
        isSubmitting,
        touched,
        setFieldTouched,
      }) => (
        <form onSubmit={handleSubmit}>
          <SignUpContainter isMobile={isMobile}>
            <Input name="firstName" label="First name" placeholder="First name" />
            <Input name="lastName" label="Last name" placeholder="Last name" />
            <Input name="email" label="Email" placeholder="Email" />
            <PasswordInput name="password" placeholder="Password" />
            <PasswordInput name="confirmPassword" placeholder="Confirm password" />
          </SignUpContainter>
          <StyledFormControl>
            <Checkbox
              checked={values.termsAndConditions}
              name="termsAndConditions"
              onChange={() => {
                setFieldTouched('termsAndConditions', true);
                setFieldValue('termsAndConditions', !values.termsAndConditions);
              }}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Link href="/policy/terms-of-use" prefetch={false}>
              <MuiLink color="primary">I agree Terms & Conditions</MuiLink>
            </Link>
          </StyledFormControl>
          <ErrorText error>
            {errors && touched.termsAndConditions && errors.termsAndConditions}
          </ErrorText>
          <StyledButton
            buttonText="Register"
            type="submit"
            loading={isSubmitting}
            loaderColor="#fff"
          />
        </form>
      )}
    </Formik>
  );
};
export default RegisterForm;
