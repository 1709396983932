import React, { useState } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { FormControl, OutlinedInputProps } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input, { InputProps } from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import styled from 'styled-components';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const ErrorText = styled(FormHelperText)`
  font-size: 10px !important;
`;

export interface IPasswordInput {
  name: string;
  label?: string;
  placeholder: string;
  outlined?: boolean;
  className?: string;
}

type OutlinedProps = Omit<OutlinedInputProps, 'error'>;

interface InputOutlinedStyled extends OutlinedProps {
  error: any;
}
interface InputStyled extends InputProps {
  label?: string;
  error: any;
}
const InputOutlinedStyled = styled(OutlinedInput)<InputOutlinedStyled>``;
const InputStyled = styled(Input)<InputStyled>``;

export default ({ name, label, placeholder, outlined, className }: IPasswordInput) => {
  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext<
    FormikValues
  >();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const valueTyped = values[name];
  const touchedTyped = touched[name];
  const errorTyped = errors[name];
  return (
    <FormControl className={className}>
      {(outlined && (
        <InputOutlinedStyled
          label={label}
          autoComplete="off"
          value={valueTyped}
          error={errors && touchedTyped && errorTyped}
          placeholder={placeholder}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
          }}
          onBlur={() => {
            setFieldTouched(name, true);
          }}
          id={name}
          aria-describedby={`${name}-text`}
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      )) || (
        <InputStyled
          label={label}
          autoComplete="off"
          value={valueTyped}
          error={errors && touchedTyped && errorTyped}
          placeholder={placeholder}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
          }}
          onBlur={() => {
            setFieldTouched(name, true);
          }}
          type={showPassword ? 'text' : 'password'}
          id={name}
          aria-describedby={`${name}-text`}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      )}
      <ErrorText error id={`${name}-text`}>
        {errors && touchedTyped && errorTyped}
      </ErrorText>
    </FormControl>
  );
};
