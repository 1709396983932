export default {
  validations: {
    required: 'This field is required. Please edit the field',
    require: {
      title: 'A minimum length of 5 characters is required. Please edit the field.',
      description: 'A minimum length of 20 characters is required. Please edit the field.',
      price: 'Expected price is required. Please edit the field.',
      rentValue: 'Rental Value is required. Please edit the field.',
      rentPeriod: 'Rental period is required.',
      createRentalAgreement: {
        firstPartyName: 'First Party Name is required',
        firstPartyAddress: 'First Party Address is required',
        firstPartycnic: 'First Party CNIC Number is required',
        secondPartyName: 'Second Party Name is required',
        secondPartyAddress: 'Second Party Address is required',
        secondPartycnic: 'Second Party CNIC Number is required',
        securityAdvance: 'Security Advance is required',
        termOfBusiness: 'Terms of Business is required',
        startDate: 'Start Date is required',
        endDate: 'End Date is required',
      },
      firstname: 'First Name is required.',
      lastname: 'Last Name is required',
      username: 'User name is required',
      email: 'Email address is required',
      password: 'Password is required',
      retypePassword: 'Retype your password',
      phoneNumber: 'Phone number is required.',
    },
    integer: 'Value must be integer',
    validCNIC: 'Please enter a valid CNIC Number such as 12345-6789012-3',
    passwordMatch: 'Passwords do not match',
    password: 'Password must container 8 characters including Uppercase/Lowercase and number',
    agreeTerms: 'Must Agree with Terms and Conditions',
    title: 'Title should not include any special characters',
    description: 'Description too short. Should be at least 20 characters',
    phoneNumber: 'Invalid Phone number. Number should be like 331XXXXXXX',
  },
  exception: {
    genericException: 'Looks like Something went wrong. Please try again later.',
  },
  alert: {
    verificationEmail:
      'Dear User, Kindly verify your Email Address to have full access to the Application',
    verificationSMS:
      'Dear User, Kindly verify your Phone Number to have full access to the Application',
    verification:
      'Dear User, Kindly verify your Phone Number and Email Address to have full access to the Application',
  },
  tooltips: {
    createListing: {
      title: 'Mention the key features of your item e.g. brand, model, age, type)',
      description: 'Include condition, features and reason for renting out',
      selectDate: 'Select dates till you want to rent your item.',
      expectedPrice: 'Market Price of the item',
      rentValue: "Rental value you're seeking per day",
      address: 'Must include your City or Area Name',
      rentPeriod: 'Number of days the item is rented for',
      isNegotiable: 'Price is Negotiable or Fixed price',
      condition: 'Condition of item',
      rentPer: 'Rent per value',
    },
    listings: {
      view: 'View this Listing',
      edit: 'Edit this Listing',
      delete: 'Delete this Listing',
      generateAgreement: 'Generate a Rental Agreement against this Listing',
    },
    createRentalAgreement: {
      firstParty: {
        name: 'Should contain the name of the person who owns this item',
        address: 'Should contain the Full Address of the person who owns this item',
        cnic: 'Should contain the original CNIC Number of the person who owns this item',
      },
      secondParty: {
        name: "Should contain the name of the person who's renting this item",
        address: "Should contain the Full Address of the person who's renting this item",
        cnic: "Should contain the original CNIC Number of the person who's renting this item",
      },
      rentValue: 'Final Rental value with the Second Party',
      rentalPeriod: 'Final Rental Period in number of days',
      securityAdvance: 'Should be the appropriate Security value as per your rental item',
      termOfBusiness: 'Should be the valid term of conducting this rental transaction',
      selectDate: 'Select dates till you want to rent your item.',
      expiryDate: 'Date, this agreement will expire.',
    },
  },
};
