import React, { ReactElement } from 'react';
import { Formik, FormikValues } from 'formik';
import styled from 'styled-components';
import { useMediaQuery } from '@material-ui/core';
import { LoginSchema } from '../../utils/validationSchemas';
import Input from '../Common/formik/input';
import PasswordInput from '../Common/formik/input/Password';
import { BREAKPOINTS } from '../../utils/constants';
import Button from '../Common/formik/button';

type TStyledProps = {
  isMobile?: boolean;
};
const FormContainer = styled('div')<TStyledProps>`
  display: flex;
  width: 100%;
  flex-direction: ${({ isMobile }) => (isMobile && 'column') || 'row'};
  justify-content: space-between;
  margin-bottom: 10px;
  .MuiFormControl-root {
    flex-grow: 1;
    input {
      padding: 10px 0;
    }
  }
`;
const StyledButton = styled(Button)`
  width: 100%;
  height: 50px;
  margin-top: 20px !important;
  background: ${({ theme }) => theme.colors.primary} !important;
  color: white !important;
  &:hover {
    background: ${({ theme }) => theme.colors.primary} !important;
  }
`;
const LoginContainer = styled(FormContainer)<TStyledProps>`
  flex-direction: column;
  align-items: stretch;
  .MuiFormControl-root {
    //max-width: ${({ isMobile }) => (isMobile && '100%') || '45%'};
    max-width: 100%;
    margin-top: ${({ isMobile }) => (isMobile && '20px') || '5px'};
  }
`;
const ForgotPassword = styled.div`
  margin: 0;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    transition: color 0.2s ease-in-out;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

type TProps = {
  setMode: (mode: string) => void;
  handleSubmit: (values: FormikValues) => Promise<void>;
};
export default ({ setMode, handleSubmit }: TProps): ReactElement => {
  const isMobile = useMediaQuery(BREAKPOINTS.MOBILE);
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={async (values, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        await handleSubmit(values);
        formikHelpers.setSubmitting(false);
      }}
      validationSchema={LoginSchema}
    >
      {({ handleSubmit: submit, isSubmitting }) => {
        return (
          <form onSubmit={submit}>
            <LoginContainer isMobile={isMobile}>
              <Input label="Email" name="email" placeholder="Enter user name" />
              <PasswordInput label="Password" name="password" placeholder="Enter password" />
            </LoginContainer>
            <ForgotPassword>
              <span
                aria-hidden
                onClick={() => setMode('forgotPassword')}
                onKeyDown={() => setMode('forgotPassword')}
              >
                Forgotten your password?
              </span>
            </ForgotPassword>
            <StyledButton
              buttonText="Login"
              type="submit"
              loading={isSubmitting}
              loaderColor="#fff"
            />
          </form>
        );
      }}
    </Formik>
  );
};
