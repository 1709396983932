import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MuiCloseIcon from '@material-ui/icons/Close';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled from 'styled-components';
import { BREAKPOINTS } from 'src/utils/constants';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
const StyledContent = styled(DialogContent)<{ minWidth: string | undefined }>`
  min-width: ${({ minWidth }) => minWidth || 'auto'};
`;
const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  width: 100%;
`;
const CloseIcon = styled(MuiCloseIcon)`
  cursor: pointer;
  &:hover {
    transition: color 0.2s ease-in-out;
    color: ${({ theme }) => theme.colors.primary} !important;
  }
`;

interface IModalProps {
  open: boolean;
  handleClose: (event: object) => void;
  children: ReactElement;
  title?: string;
  contentWidth?: string;
  className?: string;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export default ({
  open,
  handleClose,
  children,
  title,
  contentWidth,
  className,
  maxWidth,
}: IModalProps) => {
  const classes = useStyles();
  const fullScreen = useMediaQuery(BREAKPOINTS.TABLETS);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      className={className}
      maxWidth={maxWidth}
    >
      {fullScreen && (
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      {!fullScreen && (
        <DialogHeader>
          {title && <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>}
          <CloseIcon onClick={handleClose} />
        </DialogHeader>
      )}
      <StyledContent minWidth={contentWidth}>{children}</StyledContent>
    </Dialog>
  );
};
