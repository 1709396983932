const specialCharRegex = /^[a-zA-Z0-9 ]+$/;
// const passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
const passwordReg = /^(?=.*[a-zA-Z]).{8,}$/;
const cnicRegex = /^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$/gm;
const numericRegex = new RegExp(/^\d+$/);
const lettersRegex = new RegExp(/^[a-zA-Z]+$/);

export default {
  specialCharRegex,
  passwordReg,
  cnicRegex,
  numericRegex,
  lettersRegex,
};
