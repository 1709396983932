import Router from 'next/router';
import cookies from 'js-cookie';
import { GetServerSidePropsContext } from 'next';
import cookiee from 'cookie';
import client from './axios';
import { IListings } from '../interface';

export const fetcher = (url: string) =>
  client
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err.response.data);
    });

export const formatPrice = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'PKR',
  minimumFractionDigits: 0,
});
export function redirect(ctx: GetServerSidePropsContext, path: string) {
  const { res } = ctx;
  if (res) {
    // res.writeHead(301, { Location: path });
    // res.end();
    res.setHeader('location', path);
    res.statusCode = 302;
    res.end();
  } else {
    Router.push(path);
  }
}
export const authenticatedRoute = (ctx: GetServerSidePropsContext) => {
  const { req } = ctx;
  const authCookie = req.headers.cookie;
  let isAuthenticated = false;
  if (authCookie) {
    const isPhoneVerified =
      (cookiee.parse(authCookie).auth &&
        JSON.parse(cookiee.parse(authCookie).auth).user.verified_by_sms) ||
      false;
    const isEmailVerified =
      (cookiee.parse(authCookie).auth &&
        JSON.parse(cookiee.parse(authCookie).auth).user.verified_by_email) ||
      false;
    if (isEmailVerified && isPhoneVerified) {
      isAuthenticated = !!cookiee.parse(authCookie).auth;
      // redirect(ctx, '/post-listing');
    }
  }
  if (!isAuthenticated) {
    redirect(ctx, '/');
  }
};

export const getCookie = (name: string) => {
  const cookie = cookies.get(name);
  if (!cookie) {
    return null;
  }
  return JSON.parse(cookie);
};
export const setCookie = (name: string, value: any, expires: number | null) => {
  cookies.set(name, value, {
    expires: expires || 1,
  });
};
export const removeCookie = (name: string) => cookies.remove(name);

export const generateDescriptionFromListings = (
  listings: IListings,
  startString: string,
  endString: string
) => {
  const categories: string[] = [];
  const description: string[] = [];
  listings.data.forEach((listing, index) => {
    if (
      !categories.includes(listing.parent_category.name) &&
      !categories.includes(listing.child_category.name)
    ) {
      description.push(
        `${startString}${listing.parent_category.name}${endString}${
          index < listings.data.length && ','
        }`
      );
      description.push(
        `${startString}${listing.child_category.name}${endString}${
          index < listings.data.length && ','
        }`
      );
      categories.push(listing.parent_category.name);
      categories.push(listing.child_category.name);
    }
  });
  return description.join(' ');
};
