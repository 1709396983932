import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Backdrop from '@material-ui/core/Backdrop';

import useUser from '../../hooks/auth/useUser';

const StyledAppBar = styled(AppBar)`
  background-color: white !important;
  height: 180px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;
const Heading = styled('h2')`
  color: black;
  margin-top: 20px;
  width: 100%;
  text-align: center;
`;
const ButtonsContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  a {
    margin-left: 20px;
  }
`;
const StyledButton = styled(Button)`
  height: 50px !important;
  width: 150px !important;
`;
const StyledBackdrop = styled(Backdrop)`
  display: flex !important;
  align-items: flex-end !important;
`;
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
const RedirectModal = () => {
  const classes = useStyles();
  const { getUserCookiesConsent, updateUserCookiesConsent } = useUser();
  const [open, setOpen] = useState(true);

  const consent = getUserCookiesConsent();

  const handleClose = () => {
    updateUserCookiesConsent(false);
    setOpen(false);
  };
  const AppLink = 'https://rentablepk.page.link/app';
  if (typeof consent === 'object')
    return (
      <StyledBackdrop className={classes.backdrop} open={open}>
        <StyledAppBar position="static">
          <Heading>Download Rentable Application</Heading>
          <Divider />
          <ButtonsContainer>
            <StyledButton variant="contained" color="secondary" onClick={handleClose}>
              Continue
            </StyledButton>
            <Link href={AppLink}>
              <StyledButton variant="contained" color="primary">
                Run App
              </StyledButton>
            </Link>
          </ButtonsContainer>
        </StyledAppBar>
      </StyledBackdrop>
    );

  return null;
};

export default RedirectModal;
