import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from '@material-ui/core';
import Modal from '../Common/modal';
import { BREAKPOINTS } from '../../utils/constants';
import ResetPasswordForm from './ResetPasswordForm';
import LoginForm from './LoginForm';
import SocialRegistration from './SocialRegistration';
import RegisterForm from './RegisterForm';
import { useAuthentication } from '../../contexts/auth/auth.context';
import useUser from '../../hooks/auth/useUser';

const Container = styled.div`
  padding: 10px;
`;
const CenteredText = styled.div`
  width: 100%;
  text-align: center;
  margin: 30px 0;
  span {
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      transition: color 0.2s ease-in-out;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
const Ordivider = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  margin: 5px 0;
  &:before {
    content: ' ';
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    border-bottom: 1px solid #eee;
  }
  h6 {
    background: #fff;
    padding: 0 10px;
    color: ${({ theme }) => theme.colors.primary};
    position: relative;
    z-index: 1;
    font-size: 12px;
    display: inline-block;
  }
`;

const LoginModal = () => {
  const { loginUser } = useUser();
  const isMobile = useMediaQuery(BREAKPOINTS.MOBILE);
  const [AuthState, dispatchToAuth] = useAuthentication();
  const { currentForm, authModal } = AuthState;

  const setModal = useCallback(
    (show) => {
      dispatchToAuth({ type: 'SWITCH_AUTH_MODAL', payload: show });
    },
    [dispatchToAuth]
  );
  const switchViews = useCallback(
    (view) => {
      dispatchToAuth({ type: 'AUTH_MODAL_VIEW', payload: view });
    },
    [dispatchToAuth]
  );

  return (
    <div>
      <Modal
        title="Login / Register"
        open={authModal}
        handleClose={() => setModal(false)}
        contentWidth={(isMobile && 'auto') || '400px'}
      >
        <Container>
          {currentForm !== 'forgotPassword' && (
            <>
              <SocialRegistration type={(currentForm === 'login' && 'Sign in') || 'Register'} />
              <Ordivider>
                <h6>OR</h6>
              </Ordivider>
            </>
          )}
          {currentForm === 'login' && <LoginForm setMode={switchViews} handleSubmit={loginUser} />}
          {currentForm === 'signUp' && <RegisterForm />}
          {currentForm === 'forgotPassword' && <ResetPasswordForm setModal={setModal} />}
          {currentForm !== 'login' && (
            <CenteredText>
              <span
                aria-hidden
                onClick={() => switchViews('login')}
                onKeyDown={() => switchViews('login')}
              >
                Already have an account? Login here.
              </span>
            </CenteredText>
          )}
          {currentForm === 'login' && (
            <CenteredText>
              <span
                aria-hidden
                onClick={() => switchViews('signUp')}
                onKeyDown={() => switchViews('signUp')}
              >
                Don&apos;t have an account? Create one here.
              </span>
            </CenteredText>
          )}
        </Container>
      </Modal>
    </div>
  );
};
export default LoginModal;
