import throttle from 'lodash.throttle';
import { useNotifier } from 'src/contexts/notification/notification.context';
import { forgetPassword, signIn, register, socialLogin as socialLoginInternal } from 'src/api/auth';
import { FormikValues } from 'formik';
import { useAuthDispatch, useAuthState } from 'src/contexts/auth/auth.context';
import { IResetPasswordProps } from 'src/interface';
import DashboardApis from 'src/api/dashboard';
import { getCookie, setCookie } from '../../utils/helpers';

const useUser = () => {
  const { errorAlert, errorAlerts, successAlert } = useNotifier();
  const dispatchToAuth = useAuthDispatch();
  const authState = useAuthState();

  const socialLogin = async (socialToken: string, provider: 'google' | 'facebook') => {
    const throttledCall = throttle(async () => {
      try {
        const result = await socialLoginInternal({ accessToken: socialToken, provider });
        if (result.status === 200) {
          const { data } = result.data;
          const { user, meta } = data;
          const { token, refresh_token } = meta;
          dispatchToAuth({
            type: 'LOGIN_SUCCESS',
            payload: {
              user,
              token,
              refreshToken: refresh_token,
            },
          });
        } else {
          errorAlert(result.data.message);
        }
      } catch (error) {
        errorAlert('Looks like an error happened');
        console.log(error);
      }
    });
    return throttledCall();
  };
  const loginUser = async (values: FormikValues) => {
    const { email, password } = values;
    const throttledCall = throttle(async () => {
      try {
        const result = await signIn({ email, password });
        if (result.status === 200) {
          const { data } = result.data;
          const { user, meta } = data;
          const { token, refresh_token } = meta;
          dispatchToAuth({
            type: 'LOGIN_SUCCESS',
            payload: {
              user,
              token,
              refreshToken: refresh_token,
            },
          });
        } else {
          errorAlert(result.data.message);
        }
      } catch (error) {
        errorAlert('Looks like an error happened');
        console.log(error);
      }
    });
    return throttledCall();
  };
  const registerUserWithEmail = async (values: FormikValues) => {
    const bodyFormData = new FormData();
    bodyFormData.append('first_name', values.firstName);
    bodyFormData.append('last_name', values.lastName);
    bodyFormData.append('email', values.email);
    bodyFormData.append('phone_number', '');
    bodyFormData.append('password', values.password);
    bodyFormData.append('password_confirmation', values.confirmPassword);
    const throttledCall = throttle(async () => {
      try {
        const result = await register(bodyFormData);
        if (result.status === 200) {
          const { data } = result.data;
          const { user, meta } = data;
          const { token, refresh_token } = meta;
          dispatchToAuth({
            type: 'LOGIN_SUCCESS',
            payload: {
              user,
              token,
              refreshToken: refresh_token,
            },
          });
        } else if (result.data.errors) {
          errorAlerts(Object.values(result.data.errors));
        } else errorAlert(result.data.message);
      } catch (error) {
        errorAlert('Looks like an error happened');
        console.log(error);
      }
    });
    return throttledCall();
  };
  const resetUserPassword = async (email: string) => {
    const throttledCall = throttle(async () => {
      try {
        const formData = new FormData();
        formData.append('email', email);
        const result = await forgetPassword(formData);
        if (result.status === 200) {
          successAlert(result.data.message);
          return true;
        }
        errorAlert(result.data.message);
        return false;
      } catch (error) {
        errorAlert('Looks like an error happened');
        console.log(error);
        return false;
      }
    });
    return throttledCall();
  };
  const logout = () => {
    dispatchToAuth({
      type: 'LOGOUT',
    });
  };
  const resetPassword = async (values: IResetPasswordProps) => {
    try {
      const result = await DashboardApis.resetPassword({
        token: authState.auth?.token || '',
        values,
      });
      if (result.status === 200) {
        successAlert(result.data.message);
        return true;
      }
      if (result.data.errors) {
        errorAlerts(Object.values(result.data.errors));
      } else errorAlert(result.data.message);

      return false;
    } catch (error) {
      console.error(error);
      errorAlert('Something went wrong');
      return false;
    }
  };
  const updateProfilePicture = async (image: File) => {
    try {
      const result = await DashboardApis.updateProfilePicture({
        token: authState.auth?.token || '',
        image,
      });
      if (result.status === 200) {
        dispatchToAuth({ type: 'UPDATE_USER', payload: result.data.user });
        successAlert(result.data.message);
      }
      errorAlert(result.data.message);
    } catch (error) {
      console.error(error);
      errorAlert('Something went wrong');
    }
  };
  const updateUserCookiesConsent = (val: boolean) => {
    setCookie('cookiesConsent', val, 1 / 24);
  };
  const getUserCookiesConsent = () => {
    const consent = getCookie('cookiesConsent');
    return consent;
  };

  return {
    user: authState.auth?.user || null,
    accessToken: authState.auth?.token || '',
    loginUser,
    socialLogin,
    logout,
    resetUserPassword,
    registerUserWithEmail,
    resetPassword,
    updateProfilePicture,
    updateUserCookiesConsent,
    getUserCookiesConsent,
  };
};
export default useUser;
