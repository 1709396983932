import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiButton from '@material-ui/core/Button';

const Button = styled(MuiButton)`
  cursor: ${(props) =>
    // @ts-ignore
    props.cursor};
  font-size: 13px !important;
  &:before {
    background: ${(props) => props.color} !important;
  }
`;
const Loader = styled(CircularProgress)`
  // @ts-ignore
  color: ${({
    theme,
    // @ts-ignore
    loaderColor,
  }) => (loaderColor && loaderColor) || theme.colors.primary} !important;
`;

interface IFormButtonProps {
  onClick?: () => void;
  buttonText: string;
  type: 'button' | 'submit';
  loading?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
  loaderColor?: string;
}

const FormButton: React.FunctionComponent<IFormButtonProps> = ({
  onClick,
  buttonText,
  type,
  loading,
  disabled,
  style,
  className,
  loaderColor,
}) => {
  return (
    <Button
      className={className}
      onClick={onClick}
      type={type}
      disabled={loading || disabled}
      // @ts-ignore
      cursor={loading ? 'not-allowed' : 'pointer'}
      style={style}
    >
      {(loading && (
        <Loader
          size={25}
          // @ts-ignore
          loaderColor={loaderColor}
        />
      )) ||
        buttonText}
    </Button>
  );
};

export default FormButton;
