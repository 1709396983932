import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import styled from 'styled-components';
import config from 'src/config';
import useUser from 'src/hooks/auth/useUser';

const CenteredText = styled.div`
  width: 100%;
  text-align: center;
  margin: 10px 0;
`;
const SocialContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SocialList = styled.ul`
  margin: 0;
  margin-bottom: 30px;
  margin-right: -15px;
  padding-left: 0;
  list-style: none;
  display: flex;
  width: 100%;
  justify-content: center;
  @media (max-width: 767px) {
    margin: 0;
    flex-direction: column;
    align-items: stretch;
  }
`;
const SocialListItem = styled.li`
  width: calc(45% - 15px);
  margin-right: 15px;
  //float: left;
  padding-left: 5px;
  padding-right: 5px;
  padding: 5px 0;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
const anchor = styled('a')`
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
  @media screen and (max-width: 450px) {
    margin: 0;
    padding: 10px 5px;
  }
`;
const AnchorText = styled.span`
  @media (max-width: 767px) {
    flex: 0 0 80%;
    text-align: center;
  }
`;
const Facebook = styled(anchor)`
  background: #1778f2;
  &:hover {
    transition: background-color 0.2s ease-in-out;
    background: #1762f2;
  }
  img {
    height: 17px;
    margin: 9px 10px;
  }
`;
const Google = styled(anchor)`
  background: #4285f4;
  &:hover {
    transition: background-color 0.2s ease-in-out;
    background: #1762f2;
  }
  img {
    height: 35px;
    margin-right: 10px;
  }
`;
const Img = styled('img')`
  max-width: 100%;
  width: auto;
`;
type TProps = {
  type: string;
};

const SocialLogin = ({ type }: TProps) => {
  // const { errorAlert } = useNotifier();
  const { socialLogin } = useUser();
  const responseFacebook = async (response: any) => {
    const { accessToken } = response;
    await socialLogin(accessToken, 'facebook');
  };
  const responseGoogle = async (response: any) => {
    const { accessToken } = response;
    await socialLogin(accessToken, 'google');
  };
  return (
    <SocialContainer>
      <CenteredText>
        <span>{`${type} With`}</span>
      </CenteredText>
      <SocialList>
        <SocialListItem>
          <FacebookLogin
            appId={config.facebookId}
            fields="name,email,picture"
            callback={responseFacebook}
            onFailure={() => {
              // errorAlert('Facebook login failed');
            }}
            render={({ onClick, isDisabled, isProcessing }) => (
              <Facebook onClick={() => (!isProcessing && !isDisabled && onClick()) || null}>
                <Img src="/assets/icons/facebook.png?webp" alt="Facebook icon" />
                <AnchorText>{`${(isProcessing && '...loading') || 'Facebook'}`}</AnchorText>
              </Facebook>
            )}
          />
        </SocialListItem>
        <SocialListItem>
          <GoogleLogin
            onSuccess={responseGoogle}
            onFailure={() => {
              // errorAlert('Google login failed');
            }}
            clientId={config.googleId || ''}
            render={({ onClick }) => (
              <Google onClick={() => onClick()}>
                <Img src="/assets/icons/google.png?webp" alt="Google icon" />
                <AnchorText>Google</AnchorText>
              </Google>
            )}
          />
        </SocialListItem>
      </SocialList>
    </SocialContainer>
  );
};
export default SocialLogin;
