import * as Yup from 'yup';
import Messages from './messages';
import Regex from './regex';

const { validations } = Messages;

export const PhoneNumberSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .nullable()
    .min(10, Messages.validations.phoneNumber)
    .max(10, Messages.validations.phoneNumber)
    .required(Messages.validations.require.phoneNumber),
});
export const ReportListingSchema = Yup.object().shape({
  option: Yup.string().required('Please select one option'),
  details: Yup.string()
    .max(250, 'Number of alphabets exceeded the maximum 250')
    .required(Messages.validations.required),
});

export const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid Email').required(validations.required),
  password: Yup.string().required(validations.required),
});

export const ResetPasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required(validations.required),
  password: Yup.string()
    .matches(Regex.passwordReg, 'Password must contain 8 characters atleast')
    .required(validations.require.password),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], validations.passwordMatch)
    .required(validations.require.retypePassword),
});

export const RegisterSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .matches(Regex.lettersRegex, 'Name should contain letters A-Z or a-z')
    .required(validations.require.firstname),
  lastName: Yup.string()
    .trim()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .matches(Regex.lettersRegex, 'Name should contain letters A-Z or a-z')
    .required(validations.require.lastname),

  email: Yup.string().email().required(validations.require.email),
  password: Yup.string()
    .matches(Regex.passwordReg, 'Password must be atleast 8 characters')
    .required(validations.require.password),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], validations.passwordMatch)
    .required(validations.require.retypePassword),
  termsAndConditions: Yup.bool()
    .test('termsAndConditions', `${validations.agreeTerms}`, (value) => {
      return value === true;
    })
    .required(validations.required),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email().required(validations.require.email),
});

/* export const ResetPasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required(validations.require.password),
  password: Yup.string()
    .matches(Regex.passwordReg, validations.password)
    .required(validations.require.password),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], validations.passwordMatch)
    .required(validations.require.retypePassword),
}); */

/* export const CreateWantedListingSchema = Yup.object().shape({
  title: Yup.string()
    .min(5, 'Too short title')
    .matches(Regex.specialCharRegex, validations.title)
    .required(validations.require.title),
  description: Yup.string()
    .min(20, 'Too short description')
    .required(validations.require.description),
  parentCategory: Yup.mixed().required('Select Parent Category'),
  childCategory: Yup.mixed().required('Select Parent Category'),
  address: Yup.string().required('Select location').nullable('Enter address'),
  rentPeriod: Yup.number().typeError(validations.integer).required(validations.require.rentPeriod),
  rentValue: Yup.number()
    .test('match', 'Rent value should be less than the price', function (rentValue) {
      return rentValue < parseInt(this.parent.expectedPrice);
    })
    .required(validations.require.rentValue),
  images: Yup.mixed()
    .required('Select Images for Ad')
    .test('fileFormat', 'Single image upload', (value) => {
      if (value) {
        return !(value.length > 1);
      }
      return false;
    }),
}); */
export const EditListingSchema = Yup.object().shape({
  title: Yup.string()
    .min(5, 'Too short title')
    .matches(Regex.specialCharRegex, `Title should not include any special characters`)
    .required(validations.require.title),
  description: Yup.string()
    .min(20, 'Too short description')
    .max(250, 'Max Characters length reached of 250 characters')
    .required(validations.require.description),
  address: Yup.string().required('Enter location'),
  expected_price: Yup.number().min(0).required(validations.require.price),
  rent_value: Yup.number()
    .test('match', 'Rent value should be less than the price', function (rentValue) {
      return rentValue < parseInt(this.parent.expected_price, 10);
    })
    .required(validations.require.rentValue),
});
export const ListingDetailsSchema = Yup.object().shape({
  title: Yup.string()
    .min(5, 'Too short title')
    .matches(Regex.specialCharRegex, `Title should not include any special characters`)
    .required(validations.require.title),
  description: Yup.string()
    .min(20, 'Too short description')
    .max(250, 'Max Characters length reached of 250 characters')
    .required(validations.require.description),
  // parentCategory: Yup.mixed().required('Select Parent Category'),
  // childCategory: Yup.mixed().required('Select Parent Category'),
  expectedPrice: Yup.number().min(0).required(validations.require.price),
  rentValue: Yup.number()
    .test('match', 'Rent value should be less than the price', function (rentValue) {
      return rentValue < parseInt(this.parent.expectedPrice, 10);
    })
    .required(validations.require.rentValue),
  startDate: Yup.date().nullable().required('Select Availability Date from'),
  endDate: Yup.date().nullable().required('Select Availability Date to'),
});

export const CreateListingSchema = Yup.object().shape({
  title: Yup.string()
    .min(5, 'Too short title')
    .matches(Regex.specialCharRegex, `Title should not include any special characters`)
    .required(validations.require.title),
  description: Yup.string()
    .min(20, 'Too short description')
    .required(validations.require.description),
  // parentCategory: Yup.mixed().required('Select Parent Category'),
  // childCategory: Yup.mixed().required('Select Parent Category'),
  expectedPrice: Yup.number().required(validations.require.price),
  rentValue: Yup.number()
    .test('match', 'Rent value should be less than the price', function (rentValue) {
      return rentValue < parseInt(this.parent.expectedPrice, 10);
    })
    .required(validations.require.rentValue),
  images: Yup.mixed().required('Select Images for Ad'),
  startDate: Yup.date().nullable().required('Select Availability Date from'),
  endDate: Yup.date().nullable().required('Select Availability Date to'),
});

// Rental Agreement
export const FirstPartyInformationSchema = Yup.object().shape({
  renterName: Yup.string().required(validations.require.createRentalAgreement.firstPartyName),
  renterAddress: Yup.string().required(validations.require.createRentalAgreement.firstPartyAddress),
  renterCnic: Yup.string()
    .matches(Regex.cnicRegex, validations.validCNIC)
    .required(validations.require.createRentalAgreement.firstPartycnic),
});
export const SecondPartyInformationSchema = Yup.object().shape({
  secondPartyName: Yup.string().required(validations.require.createRentalAgreement.secondPartyName),
  secondPartyAddress: Yup.string().required(
    validations.require.createRentalAgreement.secondPartyAddress
  ),
  secondPartyCnic: Yup.string()
    .matches(Regex.cnicRegex, validations.validCNIC)
    .required(validations.require.createRentalAgreement.secondPartycnic),
});
export const AgreementDetailsSchema = Yup.object().shape({
  securityAdvance: Yup.number()
    .typeError(validations.integer)
    .required(validations.require.createRentalAgreement.securityAdvance),
  rentValue: Yup.number().typeError(validations.integer).required(validations.require.rentValue),
  startDate: Yup.date().required(validations.require.createRentalAgreement.startDate),
  termOfBusiness: Yup.string()
    .max(20, 'Agreement term should be less than 20 Alphabets')
    .required(validations.require.createRentalAgreement.termOfBusiness),
});

/* export const CreateRentalAgreementSchema = Yup.object().shape({
  renterName: Yup.string().required(validations.require.createRentalAgreement.firstPartyName),
  renterAddress: Yup.string().required(validations.require.createRentalAgreement.firstPartyAddress),
  renterCnic: Yup.string()
    .matches(Regex.cnicRegex, validations.validCNIC)
    .required(validations.require.createRentalAgreement.firstPartycnic),
  secondPartyName: Yup.string().required(validations.require.createRentalAgreement.secondPartyName),
  secondPartyAddress: Yup.string().required(
    validations.require.createRentalAgreement.secondPartyAddress
  ),
  secondPartyCnic: Yup.string()
    .matches(Regex.cnicRegex, validations.validCNIC)
    .required(validations.require.createRentalAgreement.secondPartycnic),
  securityAdvance: Yup.number().required(),
  rentValue: Yup.number().typeError(validations.integer).required(validations.require.rentValue),
  rentFrom: Yup.date().required(),
  rentTo: Yup.date().required(),
  termOfBusiness: Yup.string().required('Term of business required'),
}); */
