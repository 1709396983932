import React, { Ref, useCallback } from 'react';
import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import { DEEP_LINK } from 'src/utils/constants';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

const hideAlertTime = 3000;

type NotificationProviderProps = { children: React.ReactNode };

const Close = styled(CloseIcon)`
  color: white !important;
`;

const notistackRef = React.createRef<Ref<any>>();

const onClickDismiss = (key: SnackbarKey) => () => {
  if (notistackRef.current) {
    // @ts-ignore
    // eslint-disable-next-line no-unused-expressions
    notistackRef?.current.closeSnackbar(key);
  }
};
const useStyles = makeStyles({
  info: { backgroundColor: '#363b4d' },
});
function NotificationProvider({ children }: NotificationProviderProps) {
  const classes = useStyles();
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      classes={{
        variantInfo: classes.info,
      }}
      dense
      preventDuplicate
      ref={notistackRef}
      action={(key: SnackbarKey) => (
        <Button type="button" onClick={onClickDismiss(key)}>
          Dismiss
        </Button>
      )}
    >
      {children}
    </SnackbarProvider>
  );
}
const useNotifier = () => {
  const { enqueueSnackbar } = useSnackbar();

  const errorAlerts = (errors: Array<string>) => {
    errors.forEach((error) => {
      enqueueSnackbar(error, { variant: 'error', autoHideDuration: hideAlertTime });
    });
  };

  const errorAlert = (message: string) => {
    enqueueSnackbar(message, { variant: 'error', autoHideDuration: hideAlertTime });
  };
  const successAlert = (message: string) => {
    enqueueSnackbar(message, { variant: 'success', autoHideDuration: hideAlertTime });
  };
  const persistedAlert = useCallback(
    (message: string, variant: 'default' | 'error' | 'success' | 'warning' | 'info') => {
      enqueueSnackbar(message, {
        persist: true,
        variant,
        autoHideDuration: hideAlertTime,
        action: (key) => (
          <>
            <Link href={DEEP_LINK}>
              <Button size="small" variant="contained" color="primary">
                Run App
              </Button>
            </Link>
            <IconButton onClick={onClickDismiss(key)}>
              <Close />
            </IconButton>
          </>
        ),
      });
    },
    [enqueueSnackbar]
  );

  return {
    errorAlert,
    errorAlerts,
    successAlert,
    persistedAlert,
  };
};
export { NotificationProvider, useNotifier };
