import * as React from 'react';

import LoginModal from 'src/components/LoginModal';
import { getCookie, setCookie, removeCookie } from 'src/utils/helpers';
import { Auth } from 'src/Types';
import { IUser } from 'src/interface';
import { useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';
import RedirectModal from 'src/components/RedirectModal';
import { BREAKPOINTS } from 'src/utils/constants';
import { useNotifier } from '../notification/notification.context';

// const isBrowser = typeof window !== 'undefined';
type State = {
  isAuthenticated: boolean;
  auth: Auth | null;
  currentForm: string;
  authModal: boolean;
};
type Action =
  | { type: 'LOGIN_SUCCESS'; payload: Auth }
  | { type: 'LOGOUT' }
  | { type: 'AUTH_MODAL_VIEW'; payload: string }
  | { type: 'SWITCH_AUTH_MODAL'; payload: boolean }
  | {
      type: 'UPDATE_AUTH';
      payload: Partial<State>;
    }
  | {
      type: 'UPDATE_PHONE_NUMBER';
      payload: string;
    }
  | {
      type: 'UPDATE_USER';
      payload: IUser;
    };
type Dispatch = (action: Action) => void;
type AuthProviderProps = { children: React.ReactNode };

const AuthStateContext = React.createContext<State | undefined>(undefined);
const AuthDispatchContext = React.createContext<Dispatch | undefined>(undefined);

const INITIAL_STATE = {
  isAuthenticated: false,
  auth: null,
  currentForm: 'login',
  authModal: false,
};

function authReducer(state: State, action: Action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      setCookie('auth', action.payload, null);
      return {
        ...state,
        auth: action.payload,
        isAuthenticated: true,
        authModal: false,
      };
    case 'LOGOUT':
      removeCookie('auth');
      return {
        isAuthenticated: false,
        auth: null,
      };
    case 'AUTH_MODAL_VIEW':
      return {
        ...state,
        currentForm: action.payload,
      };
    case 'SWITCH_AUTH_MODAL':
      return {
        ...state,
        currentForm: 'login',
        authModal: action.payload,
      };
    case 'UPDATE_PHONE_NUMBER':
      return {
        ...state,
        auth: {
          ...state.auth,
          user: {
            ...state.auth?.user,
            phone_number: action.payload,
            verified_by_sms: true,
          },
        },
      };
    case 'UPDATE_USER':
      setCookie('auth', { ...state.auth, user: action.payload }, null);
      return {
        ...state,
        auth: {
          ...state.auth,
          user: action.payload,
        },
      };
    case 'UPDATE_AUTH':
      return {
        ...state,
        ...action.payload,
      };
    default: {
      return state;
    }
  }
}

function AuthProvider({ children }: AuthProviderProps) {
  // @ts-ignore
  const [state, dispatch] = React.useReducer(authReducer, INITIAL_STATE);
  const { persistedAlert } = useNotifier();
  const isMobile = useMediaQuery(BREAKPOINTS.MOBILE);

  useEffect(() => {
    if (!state.auth) {
      const authInitialState = (() => getCookie('auth'))();
      // @ts-ignore
      dispatch({
        type: 'UPDATE_AUTH',
        payload: { isAuthenticated: !!authInitialState, auth: authInitialState },
      });
    }
  }, [state.auth]);
  useEffect(() => {
    const consent = getCookie('cookiesConsent');
    if (consent != null && !consent && isMobile) persistedAlert('Download Rentable App', 'info');
  }, [persistedAlert, isMobile]);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
        {state.authModal && <LoginModal />}
        {isMobile && <RedirectModal />}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a CountProvider');
  }
  return context;
}

function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a CountProvider');
  }
  return context;
}

function useAuthentication(): [State, Dispatch] {
  return [useAuthState(), useAuthDispatch()];
}

export { useAuthentication, AuthProvider, useAuthState, useAuthDispatch };
